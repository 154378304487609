<template>
  <CBox>
    <Loading :active="isLoading || achievementsLoading" />
    <CBox mt="10px">
      <BackHeader />
      <CBox
        bg="white"
        mt="20px"
        minH="100px"
        borderRadius="5px"
        padding="20px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        v-if="!isAuthenticated || currentUser.emp_id != paramId"
      >
        <CGrid templateColumns="1fr 3fr 3fr 3fr" :gap="2" h="inherit">
          <CBox mr="16px">
            <CAvatar
              size="2xl"
              :src="
                profileData.emp_photo
                  ? profileData.emp_photo
                  : 'https://bit.ly/broken-link'
              "
            />
          </CBox>
          <CBox my="auto">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="24px"
              fontWeight="700"
              color="fontMain"
            >
              {{ profileData.emp_name ? profileData.emp_name : "-" }}
            </CText>
            <CBox mt="8px" d="inline-flex">
              <CImage
                :src="require('@/assets/icon/ic-location.svg')"
                mr="8px"
              />
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="600"
                color="fontMain"
              >
                {{ profileData.province ? profileData.province : "-" }}
              </CText>
            </CBox>
          </CBox>
          <CBox textAlign="center" my="auto">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="64px"
              fontWeight="700"
              color="fontMain"
            >
              {{ profileData.total_submit ? profileData.total_submit : 0 }}
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="600"
              color="fontInformation"
            >
              Total Submit
            </CText>
          </CBox>
          <CBox textAlign="right" my="auto">
            <CButton
              variant="outline"
              backgroundColor="#fff"
              mr="2"
              color="main"
              borderColor="main"
              fontSize="14px"
              @click="copyClipboard()"
            >
              <CImage
                :src="require('@/assets/icon/copy.svg')"
                w="18px"
                h="18px"
                mr="2"
              />
              Copy URL
            </CButton>
          </CBox>
        </CGrid>
      </CBox>

      <CBox
        bg="white"
        mt="20px"
        minH="100px"
        borderRadius="5px"
        :p="['0.9em', '1em', '1.5em', '1.5em']"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        v-if="isAuthenticated && currentUser.emp_id == paramId"
      >
        <CFlex justify="space-between" align="center" mb="24px">
          <CFlex align="center">
            <CImage
              :src="require('@/assets/icon/info.svg')"
              alt="menu dashboard devcode"
              :w="['24px', '24px', '28px', '32px']"
              :h="['24px', '24px', '28px', '32px']"
              mr="14px"
              objectFit="cover"
            />
            <CText
              fontWeight="600"
              :fontSize="['16px', '16px', '16px', '22px']"
            >
              Apa itu Devcode?
            </CText>
          </CFlex>
        </CFlex>

        <CText
          fontSize="14px"
          fontFamily="Montserrat"
          fontStyle="normal"
          color="fontMain"
        >
          Kini kamu dapat mengasah kemampuan programming sesungguhnya di
          GetHired Devcode. Raih nilai tertinggi, sertifikat, dan bangun
          portofolio untuk meningkatkan kredibilitas CV kamu!
        </CText>
        <CText
          mt="12px"
          fontSize="14px"
          fontFamily="Montserrat"
          fontStyle="normal"
          color="fontMain"
        >
          Uji Kemampuan Programming dengan Praktik Langsung, Ini saatnya kamu
          uji coba kemampuan programming kamu dengan praktik langsung
          menggunakan React.JS, Vue.JS, GOlang, dan Node.JS.
        </CText>

        <CFlex
          borderTop="1px solid"
          borderColor="bgMain"
          justify="center"
          align="center"
          pt="1em"
          mt="1em"
          color="main"
          fontWeight="600"
          fontSize="14px"
        >
          <CLink
            :href="urlDevcodeGuide"
            target="_blank"
            :_hover="{ textDecoration: 'none' }"
          >
            Pelajari Lebih Lanjut
          </CLink>
        </CFlex>
      </CBox>

      <CBox
        bg="white"
        :mt="['13px', '13px', '20px', '20px']"
        minH="100px"
        borderRadius="5px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        v-if="isAuthenticated && currentUser.emp_id == paramId"
      >
        <CFlex align="center" p="24px">
          <CImage
            :src="require('@/assets/icon/menu-dashboard/uji.svg')"
            alt="menu dashboard devcode"
            :w="['24px', '24px', '28px', '32px']"
            :h="['24px', '24px', '28px', '32px']"
            mr="14px"
            objectFit="cover"
          />
          <CText
            whiteSpace="wrap"
            fontWeight="600"
            :fontSize="['16px', '16px', '16px', '22px']"
          >
            Memulai Devcode
          </CText>
        </CFlex>
        <CBox>
          <CBox
            :p="['0.9em', '1em', '1.5em', '1.5em']"
            borderTop="1px solid"
            borderColor="bgMain"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Latihan Coding</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in codeExercises"
                :key="index"
                py="24px"
                w="300px"
                minH="150px"
                flex="0 0 auto"
              >
                <CBox
                  @click.native="$router.push(`/challenge/${challenge.slug}`)"
                  cursor="pointer"
                  h="100%"
                >
                  <CFlex
                    justify="space-between"
                    align="flex-start"
                    h="90%"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="0.8em"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack w="100%">
                      <CFlex justifyContent="space-between">
                        <CText
                          :fontSize="['14px', '14px', '14px', '16px']"
                          fontWeight="600"
                          color="main"
                          d="inline-block"
                        >
                          {{ challenge.title }}
                        </CText>
                        <CText
                          v-if="displayStatusBadge(challenge.slug)"
                          :color="
                            statusColor[
                              setStatusBadgeText(
                                challenge.slug,
                                challenge.type
                              ).toLowerCase()
                            ]
                          "
                          fontFamily="Montserrat"
                          fontSize="12px"
                          fontWeight="700"
                          class="pointer"
                        >
                          {{
                            setStatusBadgeText(challenge.slug, challenge.type)
                          }}
                        </CText>
                      </CFlex>
                      <CText
                        fontSize="14px"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        color="fontMain"
                      >
                        {{ challenge.desc }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CBox>
              </CBox>
            </Draggable>
          </CBox>
          <CBox
            :p="['0.9em', '1em', '1.5em', '1.5em']"
            borderTop="1px solid"
            borderColor="bgMain"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Latihan Frontend</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in frontendExercises"
                :key="index"
                py="24px"
                w="300px"
                minH="150px"
                flex="0 0 auto"
              >
                <CBox
                  @click.native="$router.push(`/challenge/${challenge.slug}`)"
                  cursor="pointer"
                  h="100%"
                >
                  <CFlex
                    justify="space-between"
                    align="flex-start"
                    h="90%"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="0.8em"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack w="100%">
                      <CFlex justifyContent="space-between">
                        <CText
                          :fontSize="['14px', '14px', '14px', '16px']"
                          fontWeight="600"
                          color="main"
                          d="inline-block"
                        >
                          {{ challenge.title }}
                        </CText>
                        <CText
                          v-if="displayStatusBadge(challenge.slug)"
                          :color="
                            statusColor[
                              setStatusBadgeText(
                                challenge.slug,
                                challenge.type
                              ).toLowerCase()
                            ]
                          "
                          fontFamily="Montserrat"
                          fontSize="12px"
                          fontWeight="700"
                          class="pointer"
                        >
                          {{
                            setStatusBadgeText(challenge.slug, challenge.type)
                          }}
                        </CText>
                      </CFlex>
                      <CText
                        fontSize="14px"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        color="fontMain"
                      >
                        {{ challenge.desc }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CBox>
              </CBox>
            </Draggable>
          </CBox>
          <CBox
            borderLeft="1px solid"
            borderTop="1px solid"
            borderColor="bgMain"
            :p="['0.9em', '1em', '1.5em', '1.5em']"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Latihan Backend</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in backendExercises"
                :key="index"
                py="24px"
                w="300px"
                minH="150px"
                flex="1 0 auto"
              >
                <CBox
                  @click.native="$router.push(`/challenge/${challenge.slug}`)"
                  cursor="pointer"
                  h="100%"
                >
                  <CFlex
                    justify="space-between"
                    align="flex-start"
                    height="90%"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="12px"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack w="100%">
                      <CFlex justifyContent="space-between">
                        <CText
                          :fontSize="['14px', '14px', '14px', '16px']"
                          fontWeight="600"
                          color="main"
                          d="inline-block"
                        >
                          {{ challenge.title }}
                        </CText>
                        <CText
                          v-if="displayStatusBadge(challenge.slug)"
                          :color="
                            statusColor[
                              setStatusBadgeText(
                                challenge.slug,
                                challenge.type
                              ).toLowerCase()
                            ]
                          "
                          fontFamily="Montserrat"
                          fontSize="12px"
                          fontWeight="700"
                          class="pointer"
                        >
                          {{
                            setStatusBadgeText(challenge.slug, challenge.type)
                          }}
                        </CText>
                      </CFlex>
                      <CText
                        fontSize="14px"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        color="fontMain"
                      >
                        {{ challenge.desc }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CBox>
              </CBox>
            </Draggable>
          </CBox>
          <CBox
            borderLeft="1px solid"
            borderTop="1px solid"
            borderColor="bgMain"
            :p="['0.9em', '1em', '1.5em', '1.5em']"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Latihan Mobile</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in mobileExercises"
                :key="index"
                py="24px"
                w="300px"
                minH="150px"
                flex="1 0 auto"
              >
                <CBox
                  @click.native="$router.push(`/challenge/${challenge.slug}`)"
                  cursor="pointer"
                  h="100%"
                >
                  <CFlex
                    justify="space-between"
                    align="flex-start"
                    height="90%"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="12px"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack w="100%">
                      <CFlex justifyContent="space-between">
                        <CText
                          :fontSize="['14px', '14px', '14px', '16px']"
                          fontWeight="600"
                          color="main"
                          d="inline-block"
                        >
                          {{ challenge.title }}
                        </CText>
                        <CText
                          v-if="displayStatusBadge(challenge.slug)"
                          :color="
                            statusColor[
                              setStatusBadgeText(
                                challenge.slug,
                                challenge.type
                              ).toLowerCase()
                            ]
                          "
                          fontFamily="Montserrat"
                          fontSize="12px"
                          fontWeight="700"
                          class="pointer"
                        >
                          {{
                            setStatusBadgeText(challenge.slug, challenge.type)
                          }}
                        </CText>
                      </CFlex>
                      <CText
                        fontSize="14px"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        color="fontMain"
                      >
                        {{ challenge.desc }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CBox>
              </CBox>
            </Draggable>
          </CBox>
        </CBox>
      </CBox>
      <CBox
        bg="white"
        :mt="['13px', '13px', '20px', '20px']"
        minH="100px"
        borderRadius="5px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        v-if="isAuthenticated && currentUser.emp_id == paramId"
      >
        <CFlex align="center" p="24px">
          <CImage
            :src="require('@/assets/icon/menu-dashboard/uji.svg')"
            alt="menu dashboard devcode"
            :w="['24px', '24px', '28px', '32px']"
            :h="['24px', '24px', '28px', '32px']"
            mr="14px"
            objectFit="cover"
          />
          <CText
            whiteSpace="wrap"
            fontWeight="600"
            :fontSize="['16px', '16px', '16px', '22px']"
          >
            Devcode Mini Project
          </CText>
        </CFlex>
        <CBox>
          <CBox
            :p="['0.9em', '1em', '1.5em', '1.5em']"
            borderTop="1px solid"
            borderColor="bgMain"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Front-end Mini Project</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in frontendChallenges"
                :key="index"
                width="300px"
              >
                <CLink
                  :href="`${urlDevcode}/challenge/${challenge.slug}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  :_hover="{ textDecoration: 'none' }"
                >
                  <CFlex
                    justify="space-between"
                    align="center"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="0.8em"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack>
                      <CText
                        :fontSize="['14px', '14px', '14px', '16px']"
                        fontWeight="600"
                        color="main"
                        d="inline-block"
                      >
                        {{ challenge.title }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CLink>
              </CBox>
            </Draggable>
          </CBox>
          <CBox
            borderLeft="1px solid"
            borderTop="1px solid"
            borderColor="bgMain"
            :p="['0.9em', '1em', '1.5em', '1.5em']"
          >
            <CText
              :fontSize="['16px', '16px', '16px', '20px']"
              fontWeight="600"
              d="inline-block"
            >
              Back-end Mini Project</CText
            >
            <Draggable direction="row">
              <CBox
                v-for="(challenge, index) in backendChallenges"
                :key="index"
                w="300px"
                flex="0 0 auto"
              >
                <CLink
                  :href="`${urlDevcode}/challenge/${challenge.slug}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  :_hover="{ textDecoration: 'none' }"
                >
                  <CFlex
                    justify="space-between"
                    align="center"
                    p="1em"
                    borderRadius="12px"
                    border="1px solid"
                    borderColor="bgMain"
                    mt="12px"
                    :_hover="{ bg: 'bgButtonSecondary3' }"
                  >
                    <CStack>
                      <CText
                        :fontSize="['14px', '14px', '14px', '16px']"
                        fontWeight="600"
                        color="main"
                        d="inline-block"
                      >
                        {{ challenge.title }}
                      </CText>
                      <CBox
                        :bg="
                          difficultiesColor[challenge.difficulty.toLowerCase()]
                        "
                        color="white"
                        w="60px"
                        h="20px"
                        borderRadius="34px"
                        textAlign="center"
                      >
                        <CText
                          fontWeight="600"
                          :fontSize="['12px', '12px', '12px', '12px']"
                        >
                          {{ challenge.difficulty }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CFlex>
                </CLink>
              </CBox>
            </Draggable>
          </CBox>
        </CBox>
      </CBox>
      <CBox
        bg="white"
        :mt="['13px', '13px', '20px', '20px']"
        minH="100px"
        borderRadius="5px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        v-if="isAuthenticated && currentUser.emp_id == paramId"
      >
        <CFlex align="center" justifyContent="space-between" p="24px">
          <CFlex>
            <CImage
              :src="require('@/assets/icon/menu-dashboard/mailer.svg')"
              alt="menu dashboard devcode"
              :w="['24px', '24px', '28px', '32px']"
              :h="['24px', '24px', '28px', '32px']"
              mr="14px"
              objectFit="cover"
            />
            <CText
              whiteSpace="wrap"
              fontWeight="600"
              :fontSize="['16px', '16px', '16px', '22px']"
            >
              Your Invitation Test
            </CText>
          </CFlex>
          <CBox>
            <CText fontWeight="800">{{
              `${testInvitations.length} ${
                testInvitations.length > 1 ? "Invitations" : "Invitation"
              }`
            }}</CText>
          </CBox>
          <!-- <CFlex>
            <CBox
              mr="12px"
              d="flex"
              justifyContent="center"
              alignItems="center"
              :w="['116px', '116px', '124px', '128px']"
              :h="['30px', '30px', '36px', '38px']"
              border="solid 1px"
              borderColor="gray.200"
              borderRadius="20px"
              cursor="pointer"
              :_hover="{ bg: 'bgButtonSecondary3' }"
            >
              <CImage
                :src="require('@/assets/icon/ic-filter-outline.svg')"
                alt="menu dashboard devcode"
                :w="['16px', '16px', '18px', '20px']"
                :h="['16px', '16px', '18px', '20px']"
                mr="8px"
                objectFit="cover"
              />
              <CText
                :fontSize="['12px', '12px', '12px', '14px']"
                color="fontMain"
                d="inline-block"
              >
                Filters (0)
              </CText>
            </CBox>
            <CBox
              d="flex"
              justifyContent="center"
              alignItems="center"
              :w="['116px', '116px', '124px', '128px']"
              :h="['30px', '30px', '36px', '38px']"
              border="solid 1px"
              borderColor="gray.200"
              borderRadius="20px"
              cursor="pointer"
              :_hover="{ bg: 'bgButtonSecondary3' }"
            >
              <CImage
                :src="require('@/assets/icon/ic-sort.svg')"
                alt="menu dashboard devcode"
                :w="['16px', '16px', '18px', '20px']"
                :h="['16px', '16px', '18px', '20px']"
                mr="8px"
                objectFit="cover"
              />
              <CText
                :fontSize="['12px', '12px', '12px', '14px']"
                color="fontMain"
                d="inline-block"
              >
                Sort (0)
              </CText>
            </CBox>
          </CFlex> -->
        </CFlex>
        <CGrid
          v-if="testInvitations && testInvitations.length !== 0"
          h="350px"
          overflowY="scroll"
          :template-columns="[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]"
          gridRowGap="4"
          borderTop="1px solid"
          borderColor="bgMain"
          :pt="['0.5em', '0.7em', '0.9em', '0.9em']"
          :pb="['0.9em', '1em', '1.5em', '1.5em']"
          :px="['0.9em', '1em', '1.5em', '1.5em']"
        >
          <CBox
            v-for="(invitation, index) in testInvitations"
            :key="index"
            w="320px"
            h="145px"
            flex="0 0 auto"
          >
            <CBox
              @click.native="$router.push(`/challenge/${invitation.slug}`)"
              cursor="pointer"
              h="100%"
            >
              <CFlex
                justify="space-between"
                align="flex-start"
                p="1em"
                h="100%"
                borderRadius="12px"
                border="1px solid"
                borderColor="bgMain"
                mt="12px"
                :_hover="{ bg: 'bgButtonSecondary3' }"
              >
                <CAvatar
                  w="50px"
                  h="50px"
                  mr="12px"
                  border="0"
                  name="sender-photo"
                  :src="require('@/assets/img/dummy-employee.jpg')"
                ></CAvatar>
                <CStack w="100%">
                  <CText
                    :fontSize="['12px', '12px', '12px', '14px']"
                    fontWeight="800"
                    color="fontMain"
                    d="inline-block"
                  >
                    {{ invitation.comp_name }}
                  </CText>
                  <CText
                    :fontSize="['10px', '10px', '10px', '12px']"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="600"
                    color="main"
                  >
                    {{ invitation.name }}
                  </CText>
                  <CBox
                    :bg="setInviteStatusColor(invitation.status.toLowerCase())"
                    color="white"
                    w="fit-content"
                    h="20px"
                    px="12px"
                    borderRadius="34px"
                    textAlign="center"
                  >
                    <CText
                      fontWeight="600"
                      :fontSize="['12px', '12px', '12px', '12px']"
                    >
                      {{ invitation.status }}</CText
                    >
                  </CBox>
                </CStack>
              </CFlex>
            </CBox>
          </CBox>
        </CGrid>
        <CBox
          v-else
          h="350px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          borderTop="1px solid"
          borderColor="bgMain"
        >
          <CText textAlign="center" textSize="14px" color="gray.400"
            >You don't have test invitations</CText
          >
        </CBox>
      </CBox>

      <!-- achievement and skill -->
      <CBox
        v-if="achievementsData && achievementsData.length > 0"
        :mt="['13px', '13px', '20px', '20px']"
        minH="100px"
      >
        <CFlex :direction="['column', 'column', 'column', 'row']" mb="26px">
          <!-- achievement -->
          <CBox
            bg="white"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            borderRadius="5px"
            :flex="['1', '1', '1', '3']"
            :mr="['0', '0', '0', '20px']"
            :mb="['13px', '13px', '20px', '0']"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              :fontSize="['14px', '14px', '14px', '16px']"
              fontWeight="700"
              color="fontMain"
              :px="['0.9em', '1em', '1.5em', '1.5em']"
              pt="1em"
              pb="0.7em"
            >
              Latest Coding Test ({{ achievementsData.length }})
            </CText>
            <!-- exist -->
            <CBox
              borderTop="1px"
              borderColor="gray.200"
              v-for="(r, i) in achievementsData ? achievementsData : []"
              v-bind:key="i"
            >
              <CBox
                width="100%"
                d="inline-flex"
                py="14px"
                :px="['0.9em', '1em', '1.5em', '1.5em']"
              >
                <CBox width="26px" textAlign="left">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="16px"
                    color="fontMain"
                  >
                    {{ i + 1 }}
                  </CText>
                </CBox>
                <CBox width="inherit">
                  <CBox width="inherit" d="flex" justifyContent="space-between">
                    <CBox
                      d="flex"
                      justifyContent="flex-start"
                      w="80%"                      
                    >
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        :fontSize="['13px', '13px', '14px', '14px']"
                        fontWeight="400"                        
                        mr="8px"
                      >
                        {{ r.app_type_name }}
                        <CImage
                          v-if="r.current_rank == 1"
                          :src="require('@/assets/icon/ic-first-medal.png')"
                          d="inline"
                          w="24px"
                          h="24px"
                        />
                        <CImage
                          v-if="r.current_rank == 2"
                          :src="require('@/assets/icon/ic-second-medal.png')"
                          d="inline"
                          w="24px"
                          h="24px"
                        />
                        <CImage
                          v-if="r.current_rank == 3"
                          :src="require('@/assets/icon/ic-third-medal.png')"
                          d="inline"
                          w="24px"
                          h="24px"
                        />
                      </CText>
                    </CBox>
                    <CBox>
                      <CText
                        @click.native="
                          $router.push({
                            name: 'Detail Job',
                            params: { id: r.result_id },
                          })
                        "
                        fontFamily="Montserrat"
                        fontSize="12px"
                        fontWeight="700"
                        color="activeMenu"
                        class="pointer"
                        mr="3"
                      >
                        Show Certificate
                      </CText>
                    </CBox>
                  </CBox>
                  <CBox d="inline-flex">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="12px"
                      fontWeight="500"
                      color="fontInformation"
                      mr="6px"
                    >
                      Score
                    </CText>
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="800"
                      color="fontMain"
                    >
                      {{ r.score }}
                    </CText>
                  </CBox>
                  <CBox v-if="r.hashtags && r.hashtags.length > 0">
                    <CBox d="inline-flex">
                      <CText
                        v-for="(tag, k) in r.hashtags"
                        :key="k"
                        fontFamily="Montserrat"
                        fontSize="12px"
                        fontWeight="500"
                        color="fontLabel"
                      >
                        <span class="pointer">#{{ tag.name }}</span
                        >&nbsp;
                      </CText>
                    </CBox>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
            <CBox
              align="center"
              borderTop="1px"
              borderColor="gray.200"
              py="20px"
            >
              <CText fontSize="12px" color="headerTable" align="center">
                You don't have more challenge
              </CText>
            </CBox>

            <!-- not exist -->
            <CBox v-if="achievementsData.length < 1" py="5em">
              <CImage :src="require('@/assets/img/achieve.png')" mx="auto" />
              <CText
                textAlign="center"
                textSize="14px"
                mt="1em"
                color="gray.400"
                >You don't have any challenge</CText
              >
            </CBox>
          </CBox>

          <!-- skill -->
          <CBox
            flex="1"
            bg="white"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            borderRadius="5px"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              :fontSize="['14px', '14px', '14px', '16px']"
              fontWeight="700"
              color="fontMain"
              mb="12px"
              :px="['0.9em', '1em', '1.5em', '1.5em']"
              pt="1em"
              pb="0.7em"
              borderBottom="1px"
              borderColor="gray.200"
              >Skills</CText
            >
            <CBox
              v-if="true"
              :px="['0.9em', '1em', '1.5em', '1.5em']"
              pt="0.5em"
              pb="0.5em"
              display="flex"
              flexWrap="wrap"
            >
              <Label
                v-for="(skill, index) in skills"
                v-bind:key="index"
                v-bind:title="skill"
              ></Label>
            </CBox>
          </CBox>
        </CFlex>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import {
  GET_CHALLENGER_PROFILE,
  GET_CHALLENGER_ACHIEVEMENTS,
  GET_DAY_SUBMISSION,
  GET_TIME_SUBMISSION,
  GET_SKILLS,
} from "@/store/profile.module";
// import { GET_APP_TYPES } from "@/store/app-types.module";
import { GET_TEST_INVITATIONS } from "@/store/test-invitation.module";
import Label from "@/components/button/Label";
import BackHeader from "@/components/BackHeader";
import config from "@/ConfigProvider.js";
import { CBox, CFlex, CGrid, CStack, CText, CAvatar } from "@chakra-ui/vue";
import Draggable from "../../components/Draggable.vue";

export default {
  metaInfo() {
    return {
      title:
        "Profile: " +
        (this.profileData?.emp_name ? this.profileData.emp_name : ""),
      titleTemplate: "Devcode - %s",
    };
  },
  name: "Profile",
  components: {
    Loading,
    Label,
    BackHeader,
    CFlex,
    CGrid,
    CStack,
    CBox,
    Draggable,
    CText,
    CAvatar,
  },
  data: function () {
    return {
      statusColor: {
        passed: "#00A790",
        failed: "#ED6E38",
      },
      difficultiesColor: {
        starter: "#00A790",
        easy: "#00A790",
        medium: "#1297EC",
        hard: "#ED6E38",
      },
      day: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: "#0F79BA",
        },
        xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          labels: {
            style: {
              fontSize: "10px",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#F0F0F0",
          strokeDashArray: 2,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      time: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: "#A6CEE3",
        },
        xaxis: {
          categories: [
            "05:00-09:00",
            "10:00-14:00",
            "15:00-19:00",
            "20:00-00:00",
            "01:00-04:00",
          ],
          labels: {
            style: {
              fontSize: "9px",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#F0F0F0",
          strokeDashArray: 2,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },

      testInvitations: [],

      codeExercises: config.value("CODE_EXERCISES"),
      frontendExercises: config.value("FRONTEND_EXERCISES"),
      backendExercises: config.value("BACKEND_EXERCISES"),
      mobileExercises: config.value("MOBILE_EXERCISES"),
      frontendChallenges: config.value("FRONTEND_CHALLENGES"),
      backendChallenges: config.value("BACKEND_CHALLENGES"),

      // url path
      urlGethired: config.value("GETHIRED_WEB_URL"),
      urlDevcode: config.value("DEVCODE_WEB_URL"),
      urlDevcodeGuide: config.value("DEVCODE_GUIDE_URL"),
      urlBlogDevcode: config.value("DEVCODE_BLOG_URL"),
      paramId: this.$route.params.emp_id,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.profileLoading;
    },
    profileData() {
      return this.$store.getters.profileData;
    },
    achievementsLoading() {
      return this.$store.getters.achievementsLoading;
    },
    achievementsData() {
      return this.$store.getters.achievementsData;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    daySubmission() {
      const days = this.$store.getters.daySubmission,
        result = days && days.length > 0 ? days.map((day) => day.count) : [];

      const series = [
        {
          name: "Submit",
          data: result,
        },
      ];

      if (result.length) {
        return series;
      } else {
        const defaultSeries = [
          {
            name: "Submit",
            data: [0, 0, 0, 0, 0],
          },
        ];
        return defaultSeries;
      }
    },
    timeSubmission() {
      const times = this.$store.getters.timeSubmission;
      const lastTime = [0, 0, 0, 0, 0];

      // check time between start and end time
      function checkTime(startTime, endTime, time) {
        const FORMAT = "hh:mm";

        const STARTTIME = moment(startTime, FORMAT),
          ENDTIME = moment(endTime, FORMAT);

        return moment(time, FORMAT).isBetween(STARTTIME, ENDTIME);
      }

      // time categories
      if (times && times.length > 0) {
        times?.map((submission) => {
          if (checkTime("05:00", "09:00", submission.time)) {
            lastTime[0] = lastTime[0] + submission.count;
          }
          if (checkTime("10:00", "14:00", submission.time)) {
            lastTime[1] = lastTime[1] + submission.count;
          }
          if (checkTime("15:00", "19:00", submission.time)) {
            lastTime[2] = lastTime[2] + submission.count;
          }
          if (checkTime("20:00", "00:00", submission.time)) {
            lastTime[3] = lastTime[3] + submission.count;
          }
          if (checkTime("01:00", "04:00", submission.time)) {
            lastTime[4] = lastTime[4] + submission.count;
          }
        });
      }

      const result = [
        {
          name: "Submit",
          data: lastTime,
        },
      ];
      return result;
    },
    skills() {
      return this.$store.getters.skills;
    },
    currentAppTypes() {
      return this.$store.getters.currentAppTypes;
    },
    currentTestInvitations() {
      return this.$store.getters.currentTestInvitations;
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
    currentTestInvitations() {
      this.sortInvitationByStatus();
    },
  },
  methods: {
    displayStatusBadge(slug) {
      if (this.achievementsData.length > 0) {
        return this.achievementsData.some(
          (data) => this.getSelectedSlug(data.app_type_name) === slug
        );
      } else {
        return false;
      }
    },
    setStatusBadgeText(slug, type) {
      let selectedAchievement = {};
      let selectedAssesment = {};

      if (this.achievementsData.length > 0) {
        switch (type) {
          case "FE":
            selectedAssesment = this.frontendExercises.find(
              (data) => data.slug === slug
            );
            break;
          case "BE":
            selectedAssesment = this.backendExercises.find(
              (data) => data.slug === slug
            );
            break;
          case "MOBILE":
            selectedAssesment = this.mobileExercises.find(
              (data) => data.slug === slug
            );
            break;
        }

        selectedAchievement = this.achievementsData.find(
          (data) => this.getSelectedSlug(data.app_type_name) === slug
        );

        let minimumScore = (selectedAssesment.maxScore * 70) / 100;

        if (selectedAchievement.score < minimumScore) {
          return "Failed";
        } else {
          return "Passed";
        }
      } else {
        return "";
      }
    },
    sortInvitationByStatus() {
      const invitationStatus = {
        Passed: 1,
        "Waiting for Submit": 2,
        Submitted: 3,
      };

      this.testInvitations = this.currentTestInvitations.data.sort(
        (a, b) => invitationStatus[a.status] - invitationStatus[b.status]
      );
    },
    setInviteStatusColor(status) {
      switch (status) {
        case "passed":
          return "bgInvitePassed";
        case "waiting for submit":
          return "bgInviteWaiting";
        default:
          return "bgInviteSuccess";
      }
    },
    goBack() {
      window.history.back();
    },
    copyClipboard() {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = window.location;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    async getData() {      
      const { emp_id } = this.$route.params;      
      await this.$store.dispatch(GET_CHALLENGER_PROFILE, emp_id);
      this.$store.dispatch(GET_CHALLENGER_ACHIEVEMENTS, emp_id);
      this.$store.dispatch(GET_DAY_SUBMISSION, emp_id);
      this.$store.dispatch(GET_TIME_SUBMISSION, emp_id);
      this.$store.dispatch(GET_SKILLS, emp_id);
      // this.$store.dispatch(GET_APP_TYPES);
      this.$store.dispatch(GET_TEST_INVITATIONS, { id: emp_id });
    },
    getSelectedSlug(appName) {
      if (this.currentAppTypes && this.currentAppTypes.length !== 0) {
        const appDetail = this.currentAppTypes.find((app) => app.name === appName);
        return appDetail && appDetail.slug || '';
      } else {
        return "";
      }
    },
  },
};
</script>
